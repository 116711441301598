import {ChangeDetectorRef, Component, OnInit, Optional} from '@angular/core';
import {DATE_FORMAT_YMD, DATE_FORMAT_YMD_HM, Translatable, TranslationService, DateFormatService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {WorkflowService} from '../services/workflow.service';
import {TRANSLATION_GRID_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {Workflow, Translation, Questionnaire} from '@ngmedax/common-questionnaire-types';

// hack to inject decorator declarations. must occur before class declaration!
export interface WorkflowGridComponent extends Translatable {}

@Component({
  selector: 'app-workflow-grid',
  templateUrl: './workflow-grid.component.html',
  styleUrls: ['./workflow-grid.component.css']
})
@Translatable({scope: TRANSLATION_GRID_SCOPE, keys: KEYS})
export class WorkflowGridComponent implements OnInit {
  /**
   * Whether the search is collapsed
   */
  public isSearchCollapsed = false;

  /**
   * Whether the grid is collapsed
   */
  public isGridCollapsed = false;

  /**
   * Current grid page number
   */
  public gridPageNumber = 1;

  /**
   * Number of entries to display per page
   */
  public displayPerPage = 25;

  /**
   * Loaded workflows
   */
  public workflows: Workflow[] = [];

  /**
   * Filter object
   */
  public filter: {
    name?: string;
    description?: string;
    sort?: string;
    order?: string;
    questionnaires?: string[];
  } = {
    name: '',
    description: '',
    sort: 'createdAt',
    order: 'desc',
    questionnaires: []
  };

  /**
   * Total number of entries
   */
  public total = 0;

  /**
   * Locale for workflows. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi lang support
   * @type {string}
   */
  public locale = 'de_DE';

  public defaultLocale = 'de_DE';

  /**
   * Titles of questionnaires by their uid
   */
  public questionnaireTitles: { [uid: string]: Translation } = {};

  /**
   * Loaded questionnaires
   */
  public questionnaires: Questionnaire[] = [];

  /**
   * Timeout for filter change event
   */
  private filterChangeTimeout: any = null;

  /**
   * Timeout for show preloader event
   */
  private showPreloaderTimeout: any = null;

  /**
   * date format to use in grid
   * @type {string}
   */
  public get dateFormat() {
    const format = DATE_FORMAT_YMD_HM.replace(/YYYY/, 'YY');
    return this.getDateFormat(format);
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() private dateFormatService: DateFormatService,
    private layoutService: LayoutService,
    private workflowService: WorkflowService,
    private ref: ChangeDetectorRef
  ) {
  }

  /**
   * Loads workflows on init
   */
  public ngOnInit() {
    this.layoutService.showPreloader();
    this.loadWorkflows();
    this.loadQuestionnaires();
  }

  /**
   * Event handler for when paging changes. Triggers change detection and loads workflows
   */
  public onPagingChange() {
    this.ref.detectChanges();
    this.ref.markForCheck();
    this.loadWorkflows();
  }

  /**
   * Event handler for when filter changes. Loads workflows
   */
  public onFilterChange() {
    clearTimeout(this.filterChangeTimeout);
    this.filterChangeTimeout = setTimeout(() => {
      this.loadWorkflows();
    }, 1000);
  }

  /**
   * Changes sort order on column click
   * @param {string} col
   */
  public onSortChange(col: string) {
    if (this.filter.sort === col) {
      this.filter.order = this.filter.order === 'asc' ? 'desc' : 'asc';
      this.loadWorkflows();
      return;
    }
    this.filter.sort = col;
    this.filter.order = 'asc';
    this.loadWorkflows();
  }

  /**
   * Deletes a workflow
   * @param {Workflow} workflow
   */
  public async onDeleteWorkflow(workflow: Workflow) {
    if (!workflow || (!workflow._id && !workflow.id)) {
      alert(this._(KEYS.GRID.FOUND_NO_ENTRY_BY_ID));
      return;
    }
    if (!await confirm(this._(KEYS.GRID.CONFIRM_DELETE_WORKFLOW))) {
      return;
    }
    const workflowId = workflow._id || workflow.id;
    try {
      await this.workflowService.deleteWorkflow(workflowId);
      alert(this._(KEYS.GRID.SUCCESSFULLY_DELETED_WORKFLOW));
      this.loadWorkflows();
    } catch (error) {
      console.error(error);
      alert(this._(KEYS.GRID.ERROR_DELETING_WORKFLOW));
    }
  }

  /**
   * Returns workflows
   * @returns {Workflow[]}
   */
  public getWorkflows(): Workflow[] {
    return this.workflows;
  }

  /**
   * Returns a questionnaire title by uid
   * @param {string} uid
   * @returns {string}
   */
  public getQuestionnaireTitle(uid: string): string {
    if (this.questionnaireTitles[uid] && this.questionnaireTitles[uid][this.locale]) {
      return this.questionnaireTitles[uid][this.locale];
    }
    return '';
  }

  /**
   * Loads questionnaires and fills questionnaireTitles
   */
  private loadQuestionnaires() {
    this.workflowService.loadQuestionnaires()
      .then(questionnaires => {
        if (!questionnaires || !questionnaires.length) {
          return;
        }
        this.questionnaires = questionnaires;
        for (const q of questionnaires) {
          this.questionnaireTitles[q.id] = q.meta.title;
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  /**
   * Loads workflows
   */
  private loadWorkflows() {
    this.showPreloader();
    const filter = this.getQueryFilter();
    const opts = this.getQueryOpts();
    this.workflowService.loadWorkflows(filter, opts)
      .then(result => {
        this.workflows = result.rows;
        this.total = result.total;
        this.hidePreloader();
      })
      .catch(error => {
        this.hidePreloader();
        alert(this._(KEYS.GRID.ERROR_LOADING_WORKFLOWS));
        console.log(error);
      });
  }

  /**
   * Returns query filter by filter object
   */
  private getQueryFilter() {
    const filter: any = {};
    const mappings = {name: 'name.de_DE', description: 'description.de_DE'};

    Object.keys(this.filter)
      .filter(k => this.filter[k] && ['name', 'description'].includes(k))
      .forEach(k => {
        filter[mappings[k]] = {$regex: this.filter[k], $options: 'i'};
      });

    if (this.filter.questionnaires && this.filter.questionnaires.length) {
      filter['stages.questionnaires'] = {$all: this.filter.questionnaires};
    }
    return filter;
  }

  /**
   * Returns query opts
   */
  private getQueryOpts(): { limit: number, offset: number, sort: string } {
    const queryOpts = {
      limit: this.displayPerPage,
      offset: (this.gridPageNumber - 1) * this.displayPerPage,
      sort: ''
    };
    if (!this.filter.sort) {
      return queryOpts;
    }
    queryOpts.sort = this.filter.order === 'asc' ? this.filter.sort : '-' + this.filter.sort;
    return queryOpts;
  }

  /**
   * Shows preloader after 200ms
   */
  private showPreloader() {
    this.showPreloaderTimeout = setTimeout(() => this.layoutService.showPreloader(), 200);
  }

  /**
   * Hides preloader
   */
  private hidePreloader() {
    clearTimeout(this.showPreloaderTimeout);
    this.layoutService.hidePreloader();
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
