import {RouterModule, Routes} from '@angular/router';
import {permission} from './workflow.permission';
import {WorkflowInstanceGridComponent} from './components/workflow-instance-grid.component';
import {WorkflowGridComponent} from './components/workflow-grid.component';
import {WorkflowCrudComponent} from "./components/workflow-crud.component";

const APP_ROUTES: Routes = [
  {
    path: 'module/workflow/instance/grid',
    component: WorkflowInstanceGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN,
        permission.MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN
      ]
    }
  },
  {
    path: 'module/workflow/grid',
    component: WorkflowGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_CREATE,
        permission.MYMEDAX_QUESTIONNAIRE_READ,
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE,
        permission.MYMEDAX_QUESTIONNAIRE_DELETE
      ]
    }
  },
  {
    path: 'module/workflow/crud/new',
    component: WorkflowCrudComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_CREATE,
        permission.MYMEDAX_QUESTIONNAIRE_READ,
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE,
        permission.MYMEDAX_QUESTIONNAIRE_DELETE
      ]
    }
  },
  {
    path: 'module/workflow/crud/:id',
    component: WorkflowCrudComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.MYMEDAX_QUESTIONNAIRE_CREATE,
        permission.MYMEDAX_QUESTIONNAIRE_READ,
        permission.MYMEDAX_QUESTIONNAIRE_UPDATE,
        permission.MYMEDAX_QUESTIONNAIRE_DELETE
      ]
    }
  },
];

export const routing = RouterModule.forRoot(APP_ROUTES);
