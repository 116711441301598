import {Component, OnInit, Optional} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as localForage from 'localforage';
import {Base64 as base64} from 'js-base64';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {MediaCenter, MediaCenterService} from '@ngmedax/asset';
import {DEMO_QUESTIONNAIRE, TRANSLATION_THEMING_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {SettingsThemeService} from '../services/settings-theme.service';
import {CustomCssDialogComponent} from './custom-css-dialog.component';
import {Theming} from '../../../types';

// hack to inject decorator declarations. must occur before class declaration!
export interface ThemingComponent extends Translatable {}

/**
 * Theming Component
 */
@Component({
  selector: 'app-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.css']
})
@Translatable({scope: TRANSLATION_THEMING_SCOPE, keys: KEYS})
export class ThemingComponent implements OnInit {

  /**
   * Theme main color, fonts, assets etc.
   */
  public theming: Theming = {
    mainColor: '',
    font: 'Asap',
    logo: null,
    logoAsset: null,
    logoPdf: null,
    logoPdfAsset: null,
    logoPdfPosition: null,
    logoPdfHeight: 48,
    active: false,
    customCss: ''
  }

  /**
   * Preview url
   */
  public previewUrl: SafeResourceUrl;


  /**
   * Flag to check if css syntax is valid
   */
  public isCssSyntaxValid: boolean = true;

  public assetLogoOptions = {
    accept: 'image/*',
    bucketId: 'mobile-theme-files',
    filter: {type: 'image/*'},
    callback: async (selectedFile: MediaCenter.Asset) => {
      this.theming.logoAsset = selectedFile;
      this.theming.logo = await this.mediaCenter.getImageData(selectedFile, this.assetLogoOptions.bucketId, true)
        || { width: 0, height: 0, dataUrl: '' };

      await this.settings.save({
        mobileThemingLogoAssetPreview: this.theming.logoAsset,
        mobileThemingLogoPreview: this.theming.logo
      });
    }
  }

  public assetLogoPdfOptions = {
    accept: 'image/*',
    bucketId: 'mobile-theme-files',
    filter: {type: 'image/*'},
    callback: async (selectedFile: MediaCenter.Asset) => {
      this.theming.logoPdfAsset = selectedFile;
      this.theming.logoPdf = await this.mediaCenter.getImageData(selectedFile, this.assetLogoOptions.bucketId, true)
        || { width: 0, height: 0, dataUrl: '' };

      await this.settings.save({
        mobileThemingLogoPdfAssetPreview: this.theming.logoPdfAsset,
        mobileThemingLogoPdfPreview: this.theming.logoPdf
      });
    }
  }

  /**
   * Constructor
   */
  public constructor(
    @Optional() private translationEvents: TranslationEventService,
    @Optional() private translationService: TranslationService,
    @Optional() private mediaCenter: MediaCenterService,
    private settings: SettingsThemeService,
    private modalService: NgbModal,
    private layout: LayoutService,
    private config: ConfigService,
    public sanitizer: DomSanitizer
  ) {}

  public async ngOnInit() {
    localForage.config({driver: localForage.INDEXEDDB, name: 'myMedaxPreviewStorage'});
    this.theming.font = await this.settings.getValue('mobileThemingFont') || 'Asap';
    this.theming.mainColor = await this.settings.getValue('mobileThemingMainColor');
    this.theming.logoAsset = await this.settings.getValue('mobileThemingLogoAsset');
    this.theming.logo = await this.settings.getValue('mobileThemingLogo');
    this.theming.active = await this.settings.getValue('mobileThemingActive');
    this.theming.logoPdfAsset = await this.settings.getValue('mobileThemingLogoPdfAsset');
    this.theming.logoPdf = await this.settings.getValue('mobileThemingLogoPdf');
    this.theming.logoPdfPosition = await this.settings.getValue('mobileThemingLogoPdfPosition') || 'left';
    this.theming.logoPdfHeight = await this.settings.getValue('mobileThemingLogoPdfHeight') || 48;
    this.theming.customCss = await this.settings.getValue('mobileThemingCustomCss') || '';

    await this.settings.save({
      mobileThemingFontPreview: this.theming.font,
      mobileThemingMainColorPreview: this.theming.mainColor,
      mobileThemingLogoAssetPreview: this.theming.logoAsset,
      mobileThemingLogoPreview: this.theming.logo,
      mobileThemingActivePreview: this.theming.active,
      mobileThemingLogoPdfAssetPreview: this.theming.logoPdfAsset,
      mobileThemingLogoPdfPreview: this.theming.logoPdf,
      mobileThemingLogoPdfPositionPreview: this.theming.logoPdfPosition,
      mobileThemingLogoPdfHeightPreview: this.theming.logoPdfHeight,
      mobileThemingCustomCssPreview: this.theming.customCss
    });
  }

  public async onOpenCustomCssDialog() {
    const modalRef = this.modalService.open(CustomCssDialogComponent, {
      size: 'lg',
      scrollable: true
    });

    modalRef.componentInstance.customCss = this.theming.customCss;

    try {
      const updatedCss = await modalRef.result;
      this.theming.customCss = updatedCss;

      await this.settings.save({
        mobileThemingCustomCssPreview: this.theming.customCss
      });

      alert(this._('Custom CSS erfolgreich übernommen.'));
    } catch (err) {
      // cancelled
    }
  }

  public async onFontChange() {
    await this.settings.save({
      mobileThemingFontPreview: this.theming.font
    });
  }

  public async onMainColorChange(value: string) {
    this.theming.mainColor = value;
    await this.settings.save({
      mobileThemingMainColorPreview: this.theming.mainColor
    });
  }

  public onOpenMediaCenterModalForLogo() {
    this.mediaCenter && this.mediaCenter.openModal(this.assetLogoOptions);
  }

  public onOpenMediaCenterModalForLogoPdf() {
    this.mediaCenter && this.mediaCenter.openModal(this.assetLogoPdfOptions);
  }

  public async onDeleteLogo() {
    this.theming.logo = null;
    this.theming.logoAsset = null;

    await this.settings.save({
      mobileThemingLogoPreview: '',
      mobileThemingLogoAssetPreview: ''
    });
  }

  public async onDeleteLogoPdf() {
    this.theming.logoPdf = null;
    this.theming.logoPdfAsset = null;

    await this.settings.save({
      mobileThemingLogoPdfPreview: '',
      mobileThemingLogoPdfAssetPreview: ''
    });
  }

  public async onToggle() {
    this.theming.active = !this.theming.active;

    await this.settings.save({
      mobileThemingActive: this.theming.active
    });

    this.theming.active && alert(this._('Theming wurde aktiviert.'));
    !this.theming.active && alert(this._('Theming wurde deaktiviert.'));
  }

  public async onLogoPdfPositionChange(position: any) {
    this.theming.logoPdfPosition = position;

    await this.settings.save({
      mobileThemingLogoPdfPositionPreview: this.theming.logoPdfPosition
    });
  }

  public async onOpenPreview(content: any) {
    const questionnaire = DEMO_QUESTIONNAIRE;
    const key = base64.encode(questionnaire);
    const payload = questionnaire;
    const assetCdnUrl = this.config.get('apis.asset.cdn');
    const themeApiUri = this.config.get('apis.theme.uri');
    const themeUrl = themeApiUri ? themeApiUri + '/mobile/preview.zip' : null;

    await localForage.clear();
    await localForage.setItem('previewMode', 'true');
    const b64Payload = base64.encode(JSON.stringify(payload));
    await localForage.setItem(key, b64Payload);

    await localForage.removeItem('themeUrl');
    themeUrl && await localForage.setItem('themeUrl', themeUrl);
    await localForage.setItem('assetCdnUrl', assetCdnUrl);
    const locale = this.translationService ? this.translationService.getLocale() : 'de_DE';

    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/assets/preview/?key=${key}&locale=${locale}`);
    this.modalService.open(content, {size: 'lg'});
  }

  public async onSave() {
    try {
      this.layout.showPreloader(this._('Wird gespeichert...'));

      await this.settings.save({
        mobileThemingMainColor: this.theming.mainColor,
        mobileThemingLogoAsset: this.theming.logoAsset,
        mobileThemingLogo: this.theming.logo,
        mobileThemingActive: this.theming.active,
        mobileThemingLogoPdfAsset: this.theming.logoPdfAsset,
        mobileThemingLogoPdf: this.theming.logoPdf,
        mobileThemingLogoPdfPosition: this.theming.logoPdfPosition,
        mobileThemingLogoPdfHeight: this.theming.logoPdfHeight,
        mobileThemingCustomCss: this.theming.customCss
      });

      await this.settings.saveTheme();
      await new Promise(resolve => setTimeout(resolve, 500));
      this.layout.hidePreloader();

      alert(this._('Die Einstellungen wurden gespeichert.'));
    } catch (error) {
      console.error(error);
      this.layout.hidePreloader();
      alert(this._('Fehler beim Speichern der Einstellungen.'));
    }
  }

  public canSave(): boolean {
    return !!(this.theming.mainColor && this.theming.logo && this.theming.logoAsset);
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
