<ng-template #addElement>
  <app-qa-add-question
    [position]="position"
    [hideLabel]="true"
    [question]="question"
    (done)="addQuestionPopover.close()"
  >
  </app-qa-add-question>
</ng-template>

<div class="qa-question mt-2 mb-2 qa-padding qa-bg-gray expanded" id="{{question.id}}" #qaQuestion>
  <div class="qa-question-bar qa-upper">
    <div class="btn-group btn-group-custom">
      <button
        class="btn btn-success qa-position"
        [ngClass]="{'btn-success': question.options.active, 'btn-secondary': !question.options.active}"
      >
        {{position + 1}}
      </button>
      <button class="btn btn-primary qa-question-type">
        <i class="fa" ngClass="{{mapping.icon}}"></i>
        {{_(mapping.title)}}
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.ACTIVE)"
        [ngClass]="{'qa-btn-active': question.options.active}"
        (click)="question.options.active = !question.options.active"
      >
        <i class="fa fa-check-circle"></i>
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.MARK)"
        [ngClass]="{'qa-btn-active': question.options.mark}"
        (click)="question.options.mark = !question.options.mark"
      >
        <i class="fa fa-bookmark"></i>
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.HIDE_IN_PDF)"
        [ngClass]="{'qa-btn-active': question.options.hideInPdf}"
        (click)="question.options.hideInPdf = !question.options.hideInPdf"
      >
        <i class="fa fa-file-pdf-o"></i>
        <i class="fa fa-close"></i>
      </button>
      <button
        *ngIf="question.format == 'info' || question.format == 'netDiagram'"
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.HIDE_IN_APP)"
        [ngClass]="{'qa-btn-active': question.options.hideInApp}"
        (click)="question.options.hideInApp = !question.options.hideInApp"
      >
        <i class="fa fa-mobile"></i>
        <i class="fa fa-close"></i>
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.HIDE_IN_XLS)"
        [ngClass]="{'qa-btn-active': question.options.hideInXls}"
        (click)="question.options.hideInXls = !question.options.hideInXls"
      >
        <i class="fa fa-table"></i>
        <i class="fa fa-close"></i>
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink qa-mandatory"
        [ngbTooltip]="_(KEYS.EDITOR.MANDATORY)"
        [ngClass]="{'qa-btn-active': question.options.mandatory}"
        (click)="question.options.mandatory = !question.options.mandatory"
      >
        <i class="fa fa-exclamation"></i>
      </button>
      <button
        *ngIf="feature.editor.patient.upload"
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.UPLOAD)"
        [ngClass]="{'qa-btn-active': question.options.allowUpload}"
        (click)="question.options.allowUpload = !question.options.allowUpload"
      >
        <i class="fa fa-upload"></i>
      </button>
      <button
        *ngIf="hasAssetSupport"
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.IMAGE)"
        [ngClass]="{'qa-btn-active': hasImages()}"
      >
        <i class="fa fa-file-image-o" (click)="openTab(qaQuestion, 'expanded', 'images')"></i>
      </button>
      <button
        *ngIf="hasAssetSupport"
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.VIDEOS)"
        [ngClass]="{'qa-btn-active': hasVideos()}"
      >
        <i class="fa fa-file-video-o" (click)="openTab(qaQuestion, 'expanded', 'videos')"></i>
      </button>
      <button
        *ngIf="hasAssetSupport"
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.AUDIO)"
        [ngClass]="{'qa-btn-active': hasAudio()}"
      >
        <i class="fa fa-file-audio-o" (click)="openTab(qaQuestion, 'expanded', 'videos')"></i>
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.DEPENDENCIES)"
        [ngClass]="{'qa-btn-active': hasConditions()}"
        (click)="openTab(qaQuestion, 'expanded', 'conditions')"
      >
        <i class="fa fa-code-fork"></i>
      </button>
      <button
        class="btn btn-secondary qa-btn-shrink"
        [ngbTooltip]="_(KEYS.EDITOR.VARIABLES)"
        [ngClass]="{'qa-btn-active': hasVariables()}"
        (click)="openTab(qaQuestion, 'expanded', 'variables')"
      >
        <i class="fa fa-code"></i>
      </button>
    </div>
  </div>
  <div class="qa-question-buttons">
    <button class="btn btn-primary ml-1" (click)="domHelper.toggleCls(qaQuestion, 'expanded'); fixVideoPreviews(100);">
      <i class="fa fa-toggle-expand"></i>
    </button>
    <button class="btn btn-danger ml-1" (click)="onDelete()">
      <i class="fa fa-trash"></i>
    </button>
  </div>
  <div class="qa-question-hr"></div>
  <div class="qa-question-handle">
    <div class="btn-group-vertical">
      <button class="btn btn-primary qa-handle-control">
        <i class="fa fa-arrows qa-handle-control"></i>
      </button>
      <button class="btn btn-secondary qa-show-on-expanded" (click)="onPositionChange(position - 1)">
        <i class="fa fa-arrow-up"></i>
      </button>
      <button class="btn btn-secondary qa-btn-brighten qa-no-lr-padding qa-show-on-expanded">
        <input [value]="position + 1" (change)="onPositionChange(qaPosition.value - 1);" #qaPosition>
      </button>
      <button class="btn btn-secondary qa-show-on-expanded" (click)="onPositionChange(position + 1)">
        <i class="fa fa-arrow-down"></i>
      </button>
      <button
        class="btn btn-success qa-show-on-expanded"
        [ngbPopover]="addElement"
        placement="bottom-left"
        popoverTitle="{{_(KEYS.EDITOR.ADD_ELEMENT)}}"
        triggers="manual" container="body"
        #addQuestionPopover="ngbPopover"
        (document.click)="$event.stopPropagation()"
        (click)="onAddElement(addQuestionPopover)"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="qa-question-title qa-fake-input">
    <div class="qa-mt-04" [froalaEditor]="titleEditorOptions" [(froalaModel)]="question.title[locale]"></div>
  </div>
  <div class="qa-question-tabs qa-show-on-expanded ">
    <ul ngbNav #qaQuestionTabs="ngbNav" class="nav-tabs">
      <li [ngbNavItem]="'contents'">
        <a ngbNavLink><i class="fa fa-level-down"></i> {{_(KEYS.EDITOR.OPTIONS)}}</a>
        <ng-template ngbNavContent>
          <div class="mt-3">
            <ng-container *ngIf="mapping.sub">
              <ng-container
                *ngComponentOutlet="mapping.sub;
                ndcDynamicInputs: {question: question, mapping: mapping, onFocus: onChildFocus};
                ndcDynamicOutputs: {onParentFocus: onFocusTitle}"
              >
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'descriptions'">
        <a ngbNavLink><i class="fa fa-font"></i> {{_(KEYS.EDITOR.DESCRIPTION)}}</a>
        <ng-template ngbNavContent>
          <div class="mt-3" [froalaEditor]="descriptionEditorOptions" [(froalaModel)]="question.description.text[locale]"></div>
        </ng-template>
      </li>

      <ng-container *ngFor="let media of ['images', 'videos', 'audio']">
        <li [ngbNavItem]="media" *ngIf="hasAssetSupport">
          <a ngbNavLink>
            <i class="fa"
               [ngClass]="{'fa-file-video-o': (media === 'videos'), 'fa-file-audio-o': (media === 'audio'), 'fa-image': (media === 'images') }">
            </i>
            <ng-container *ngIf="media === 'images'">
              {{_(KEYS.EDITOR.IMAGES)}}
            </ng-container>
            <ng-container *ngIf="media === 'videos'">
              {{_(KEYS.EDITOR.VIDEOS)}}
            </ng-container>
            <ng-container *ngIf="media === 'audio'">
              {{_(KEYS.EDITOR.AUDIO)}}
            </ng-container>
          </a>
          <ng-template ngbNavContent>
            <ng-container *ngIf="question && question.assets && question.assets[media]">
              <div class="qa-question-images mt-3">
                <div class="card qa-card-inline-block mr-2 mb-2 mt-2" *ngFor="let asset of question.assets[media]; let position = index;">
                  <div class="card-footer">
                    <div class="btn-toolbar justify-content-between">
                      <div class="justify-content-between">
                        <button class="btn btn-success mr-1">{{position + 1}}</button>
                        <button class="btn btn-primary" (click)="onMediaPositionChange(position, position - 1, media)">
                          <i class="fa fa-arrow-left"></i>
                        </button>
                        <button class="btn btn-primary" (click)="onMediaPositionChange(position, position + 1, media)">
                          <i class="fa fa-arrow-right"></i>
                        </button>
                        <button
                          ngbTooltip="Autom. abspielen | Steuerelemente"
                          class="btn btn-success mr-1"
                          (click)="onOpenMediaCenterModal(media, asset)">
                          <i class="fa fa-pencil"></i>
                        </button>
                      </div>
                      <button class="btn btn-danger" (click)="onDeleteMedia(position, media)"><i class="fa fa-trash"></i></button>
                    </div>
                  </div>
                  <div class="card-body">
                    <img *ngIf="media === 'images'"
                         class="card-img-top qa-question-image mb-3"
                         [src]="mediaCenter.getAssetUrl(asset, locale)"
                         (click)="onOpenMediaCenterModal(media, asset)"
                    />
                    <video *ngIf="media === 'videos'"
                           class="card-img-top qa-question-video mb-3" controls preload="metadata"
                           [attr.poster]="mediaCenter.getPreviewImage(asset, locale)"
                           [src]="mediaCenter.getAssetUrl(asset, locale)"
                    >
                    </video>
                    <audio *ngIf="media === 'audio'" controls [src]="mediaCenter.getAssetUrl(asset, locale)">
                    </audio>
                    <input placeholder="{{_(KEYS.EDITOR.DESCRIPTION)}}" class="form-control" [(ngModel)]="asset.title[locale]" />
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="btn-group mt-2">
              <label class="btn btn-sm btn-primary" (click)="onOpenMediaCenterModal(media)">
                <ng-container *ngIf="media === 'images'"><i class="fa fa-plus"></i>
                  {{_(KEYS.EDITOR.ADD_IMAGES)}}
                </ng-container>
                <ng-container *ngIf="media === 'videos'"><i class="fa fa-plus"></i>
                  {{_(KEYS.EDITOR.ADD_VIDEOS)}}
                </ng-container>
                <ng-container *ngIf="media === 'audio'"><i class="fa fa-plus"></i>
                  {{_(KEYS.EDITOR.ADD_AUDIO)}}
                </ng-container>
              </label>
            </div>
          </ng-template>
        </li>
      </ng-container>

      <li [ngbNavItem]="'conditions'">
        <a ngbNavLink><i class="fa fa-code-fork"></i> {{_(KEYS.EDITOR.DEPENDENCIES)}}</a>
        <ng-template ngbNavContent>
          <div class="input-group mt-2 mb-2">
            <span class="input-group-addon">{{_(KEYS.EDITOR.MODE)}}</span>
            <select class="move-left-2" [(ngModel)]="conditionType">
              <option value="default">{{_(KEYS.EDITOR.MODE_DEFAULT)}}</option>
              <option value="extended">{{_(KEYS.EDITOR.MODE_EXTENDED)}}</option>
            </select>
          </div>
          <hr>

          <app-qa-conditions [question]="question" *ngIf="conditionType === 'default'"></app-qa-conditions>
          <app-qa-extended-conditions [question]="question" *ngIf="conditionType === 'extended'"></app-qa-extended-conditions>
        </ng-template>
      </li>

      <li [ngbNavItem]="'variables'" *ngIf="hasQuestionnaireVariablesConfig">
        <a ngbNavLink><i class="fa fa-code"></i> {{_(KEYS.EDITOR.VARIABLES)}}</a>
        <ng-template ngbNavContent>
          <ng-container *ngIf="question.format === 'info'; else normal">
            <app-qa-variables [container]="question" [disabledScopes]="{client: true, inline: true, upload: true, fhir: true, 'pdf-form': true}"></app-qa-variables>
          </ng-container>

          <ng-template #normal>
            <app-qa-variables [container]="question" [disabledScopes]="{inline: disableInlineVarScope, 'pdf-form': disableInlineVarScope}"></app-qa-variables>
          </ng-template>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="qaQuestionTabs"></div>
  </div>
</div>
