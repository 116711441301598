<div class="impress">
  <h1>myMedax Support</h1>
  <p>Wir helfen Ihnen gerne weiter! Nutzen Sie unsere umfangreiche <a href="https://mymedax.de/hilfe" target="_blank" style="color:#2D7D79;">Hilfeseite</a> (<span>https://mymedax.de/hilfe</span>), um Antworten auf häufig gestellte Fragen und Anleitungen zu finden.</p>
  <div class="contact">
    <p><strong>Kontaktieren Sie uns:</strong></p>
    <p>Am besten erreichen Sie unseren Support per E-Mail:</p>
    <p><a href="mailto:support@mymedax.de" style="color: white;">support@mymedax.de</a></p>
    <p>Alternativ stehen wir Ihnen auch telefonisch zur Verfügung:</p>
    <p><strong>Telefon:</strong> 0681 / 952 630 20</p>
  </div>
  <p>Falls Sie eine persönliche Unterstützung benötigen, können Sie hier direkt einen Fernwartungstermin buchen:</p>
  <p><a href="https://outlook.office365.com/book/myMedaxDigitaleAnamnese@optimit.de/s/_4lYOr7Xn0-ruj_rthhQeg2" target="_blank" style="color:#2D7D79;">Fernwartungstermin buchen</a></p>
  <p>Weitere Informationen finden Sie auf unserer <a href="https://mymedax.de" style="color:#2D7D79;">Website</a>.</p>
  <div class="footer">
    <p>SUXEDO GmbH & Co. KG<br>
      Sulzbachtalstraße 128, 66125 Saarbrücken<br>
      Geschäftsführer: Dirk Becker, Franz-Werner Britz<br>
      Amtsgericht Saarbrücken HRA 11981, UST-ID Nr.: DE305573826</p>
    <p><a href="https://mymedax.de/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> | <a href="https://mymedax.de/datenschutz" target="_blank">Datenschutz</a></p>
  </div>
</div>
