

// hack to inject decorator declarations. must occur before class declaration!
import {Translatable, TranslationService} from '@ngmedax/translation';
import {Component, OnInit, Optional} from '@angular/core';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {QuestionnaireVariablesService} from '../../services/questionnaire-variables.service';
import {QuestionnaireStateService} from '../../services/questionnaire-state.service';
import {QuestionnaireEditor} from '../../../types';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';

export interface MapVariablesComponent extends Translatable {}

@Component({
  selector: 'app-qa-map-variables',
  templateUrl: './map-variables.component.html',
  styleUrls: ['./map-variables.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class MapVariablesComponent implements OnInit {
  /**
   * Locale for questionnaire. Hardcoded to "de_DE" for now.
   * We need to change this, when we implement multi language support
   * @type {string}
   */
  public locale = 'de_DE';

  public sourceScope: string;
  public targetScope: string;
  public sourceVariable: string;
  public targetVariable: string;


  /**
   * Scope names for questionnaire variables
   *
   * @type {string[]}
   */
  public scopeNames = [];

  public allowedSourceScopes = {'inline': true, 'client': true, 'scoring': true, 'virtual': true, 'formula': true};

  /**
   * Variable scopes configuration
   *
   * @type {QuestionnaireEditor.VariableScopes}
   */
  public variableScopes: QuestionnaireEditor.VariableScopes = null;

  /**
   * Current questionnaire
   *
 * @type {Questionnaire}
   */
  public questionnaire: Questionnaire;

  public constructor (
    @Optional() private translationService: TranslationService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private questionnaireState: QuestionnaireStateService,
    private valueService: ValueService
  ) {
    this.questionnaire = this.questionnaireState.getQuestionnaire();
  }

  /**
   * Initializes conditions
   */
  public async ngOnInit() {
    this.scopeNames = this.questionnaireVariables.getVariableScopeNames();
    this.variableScopes = this.questionnaireVariables.getVariableScopes();
    const v = this.valueService;
    this.initScoring();

    const pdfFormScopes = await this.questionnaireVariables.getPdfFormScopes();
    Object.assign(this.variableScopes, pdfFormScopes);
    this.scopeNames = [...this.scopeNames, ...Object.keys(pdfFormScopes)];

    // adding virtual variables
    const client = v.get(this.variableScopes, ['client', 'mappings']);
    client && !client['Anrede'] && (client['Anrede'] = 'salutation');
    client && !client['Alter'] && (client['Alter'] = 'age');

    this.initVirtual();
    this.initFormulas();
  }

  public isAllowedSourceScope(scopeName: string) {
    return !!this.allowedSourceScopes[scopeName];
  }

  public isAllowedTargetScope(scopeName: string) {
    return scopeName.match(/^pdf-form/);
  }

  public getVariablesForScope(scopeName: string) {
    return Object.keys(this.valueService.get(this.questionnaire, ['meta', 'options', 'variables', scopeName]) || {});
  }

  public isValidMapping(mapping: Questionnaire.Options.VariablesMapping) {
    return this.variableScopes[mapping.source.scope] && this.variableScopes[mapping.target.scope];
  }

  public formatVarName(varName: string) {
    const format = (varName) => JSON.parse(varName).join(' > ');
    return this.valueService.isJSON(varName) ? format(varName): varName;
  }

  public formatScopeName(scopeName: string) {
    return this.variableScopes[scopeName] && this.variableScopes[scopeName].name ? this.variableScopes[scopeName].name : scopeName;
  }

  public canAdd() {
    const hasAllSelected = this.sourceScope && this.sourceVariable && this.targetScope && this.targetVariable;
    return hasAllSelected;
  }

  public onAdd() {
    const mapping = {
      source: {
        scope: this.sourceScope,
        variable: this.sourceVariable
      },
      target: {
        scope: this.targetScope,
        variable: this.targetVariable
      }
    };

    for (const current of this.questionnaire.meta.options.variablesMapping) {
      const isSameSourceScope = current.source.scope == mapping.source.scope;
      const isSameTargetScope = current.target.scope == mapping.target.scope;
      const isSameSourceVar = current.source.variable == mapping.source.variable;
      const isSameTargetVar = current.target.variable == mapping.target.variable;
      const isSameMapping = isSameSourceScope && isSameTargetScope && isSameSourceVar && isSameTargetVar;

      if (isSameMapping) {
        return;
      }
    }

    this.questionnaire.meta.options.variablesMapping.push(mapping);
  }

  public onDelete(pos: number) {
    const del = () => this.questionnaire.meta.options.variablesMapping.splice(pos, 1);
    this.confirmDelete(del);
  }


  /**
   * Opens a deletion confirmation dialog. Executes given callback if confirmation dialog is confirmed
   *
   * @param callback
   */
  public confirmDelete(callback: Function) {
    Promise
      .resolve(confirm(this._(KEYS.EDITOR.QUESTION_DELETE_ELEMENT)))
      .then(sure => sure && callback());
  }

  /**
   * Returns keys of given object
   */
  public getObjectKeys(obj: any): string[] {
    return typeof obj !== 'object' ? [] : Object.keys(obj);
  }

  private initScoring() {
    const hasScoring = !!this.valueService.get(this.questionnaire, ['meta', 'options', 'variables', 'scoring']);

    if (!hasScoring) {
      return;
    }

    this.scopeNames.indexOf('scoring') === -1 && this.scopeNames.push('scoring');
    !this.variableScopes['scoring'] && (this.variableScopes['scoring'] = {name: 'Scoring', tooltip: 'Scoring', allowAccumulation: false, allowReturnValue: false});
  }

  private initVirtual() {
    const virtual = this.valueService.get(this.variableScopes, ['virtual']);
    (!virtual || this.scopeNames.indexOf('virtual') == -1) && this.scopeNames.push('virtual');
    !virtual && (this.variableScopes['virtual'] = {
      name: 'Virtuell',
      tooltip: 'Virtuell',
      allowAccumulation: false,
      allowReturnValue: false,
      mappings: {
        'Datum': 'date',
        'Uhrzeit': 'time'
      }
    });
  }

  private initFormulas() {
    const formulaVarNames =
      Object.keys(this.valueService.get(this.questionnaire, ['meta', 'options', 'variables', 'formula']) || {});


    if (!formulaVarNames.length) {
      return;
    }

    let mappings = {};
    for (const varName of formulaVarNames) {
      mappings[varName] = varName;
    }

    const formulas = this.valueService.get(this.variableScopes, ['formulas']);
    (!formulas || this.scopeNames.indexOf('formula') == -1) && this.scopeNames.push('formula');
    !formulas && (this.variableScopes['formula'] = {
      name: 'Formeln',
      tooltip: 'Formeln',
      allowAccumulation: false,
      allowReturnValue: false,
      mappings
    });
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
