<ng-container *ngIf="enableGroups && (groups && groups.length) && !selectedWorkflowId">
  <app-select2
    cmpCssClasses="qa-add-questionnaire"
    label="{{_(KEYS.DEFAULT.GROUPS)}}"
    [hideLabel]="false"
    [hideButton]="false"
    [multiple]="false"
    [options]="options"
    (submit)="onAddGroup($event)"
    [(selected)]="choice"
  >
    <option
      *ngFor="let group of groups"
      value="{{group.uid}}"
    >
      {{group.name[locale]}}
    </option>
  </app-select2>
</ng-container>

<app-select2
  *ngIf="!selectedWorkflowId"
  cmpCssClasses="qa-add-questionnaire mt-1"
  label="{{_(KEYS.DEFAULT.QUESTIONNAIRES)}}"
  [hideLabel]="false"
  [hideButton]="false"
  [multiple]="false"
  [options]="options"
  (submit)="onAddQuestionnaire($event)"
  [(selected)]="choice"
>
  <option
    *ngFor="let questionnaire of questionnaires"
    value="{{questionnaire.id}}"
    [disabled]="selected.indexOf(questionnaire.id) !== -1 ? true : null"
  >
    {{questionnaire.meta.title[locale]}}
  </option>
</app-select2>

<ng-container *ngIf="enableWorkflows && ((workflows && workflows.length) || selectedWorkflowId)">
  <app-select2
    cmpCssClasses="qa-add-questionnaire mt-1"
    label="{{_(KEYS.DEFAULT.WORKFLOW)}}"
    [showDeleteButton]="true"
    [hideLabel]="false"
    [hideButton]="false"
    [multiple]="false"
    [options]="options"
    [buttonFaIcon]="'fa-check'"
    (submit)="onSelectWorkflow($event)"
    (deselect)="onSelectWorkflow($event)"
    [selected]="selectedWorkflowId"
  >
    <option
      *ngFor="let workflow of workflows"
      value="{{workflow.id}}"
    >
      {{workflow.name[locale]}}
    </option>
  </app-select2>
</ng-container>

<div class="input-group-addon mt-2 select-header">
  {{_(KEYS.DEFAULT.SELECTED_QUESTIONNAIRES)}}
  <ng-container *ngIf="selectedWorkflowId">
    &nbsp;<i class="fa fa-lock"></i>
  </ng-container>
</div>

<div class="qa-selected-questionnaires" [ngClass]="{'with-groups': enableGroups}">
  <table>
    <tbody dragula='questionnaire-list-bag' [dragulaModel]="selected">
      <ng-container *ngFor="let id of selected; let pos = index">
        <tr *ngIf="questionnaireTitles[id]">
          <td class="first-entry">
            <button class="btn btn-sm btn-success qa-input-position">{{pos + 1}}</button>
              <span class="title">
                &nbsp; {{questionnaireTitles[id][locale]}}
              </span>
          </td>
          <td class="options">
            <button class="btn btn-sm btn-primary qa-handle-control" *ngIf="!selectedWorkflowId">
              <i class="fa fa-arrows qa-handle-control"></i>
            </button>

            <button class="btn btn-sm btn-primary" *ngIf="selectedWorkflowId" disabled="1">
              <i class="fa fa-arrows"></i>
            </button>

            <div class="btn-group ml-1 mr-1">
              <button class="btn btn-sm btn-primary" (click)="onPositionChange(pos, pos - 1)" [disabled]="selectedWorkflowId !== null">
                <i class="fa fa-arrow-up"></i>
              </button>
              <button class="btn btn-sm btn-primary" (click)="onPositionChange(pos, pos + 1)" [disabled]="selectedWorkflowId !== null">
                <i class="fa fa-arrow-down"></i>
              </button>
            </div>

            <button class="btn btn-sm btn-danger" (click)="onDelete(pos)" [disabled]="selectedWorkflowId !== null">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
