<div class="user-filter-group">
  <table class="table">
    <thead>
    <tr>
      <th>{{_(KEYS.CRUD.FILTER_GROUP_ID)}}</th>
      <th>{{_(KEYS.CRUD.FILTER_GROUP_DESCRIPTION)}}</th>
      <th>
        <ng-container *ngIf="!isReadonly">
          {{_(KEYS.CRUD.ASSIGN)}}
        </ng-container>
        <ng-container *ngIf="isReadonly">
          {{_(KEYS.CRUD.ASSIGNED)}}
        </ng-container>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let filterGroup of filterGroups; let pos = index;">
      <tr *ngIf="!isReadonly || selectedUserFilterGroups[filterGroup.id]" [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
        <td>
          {{filterGroup.id}}
        </td>
        <td>
          {{filterGroup.name[locale]}}
        </td>
        <td>
          <input
            type="checkbox"
            [attr.disabled]="isReadonly? true : null"
            [(ngModel)]="selectedUserFilterGroups[filterGroup.id]"
            (change)="onUpdateUserFilterGroup(filterGroup)"
          >
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

