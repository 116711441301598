import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qrcode-modal',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">QR Code</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-close"></i>
      </button>
    </div>
    <div class="modal-body text-center">
      <ng-container *ngIf="qrCodeString">
        <qrcode [qrdata]="qrCodeString" [margin]="0" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        <span class="txt-code">{{qrCodeString}}</span>
      </ng-container>
    </div>
  `,
  styles: [`
    ngx-qrcode {
      display: inline-block;
      margin-top: 10px;
    }

    .txt-code {
      top: -5px;
      position: relative;
      font-family: monospace;
      font-size: 8.1px;
      color: gray;
    }
  `]
})
export class QrCodeModalComponent {
  @Input() qrCodeString: string = '';

  constructor(private modalService: NgbModal) {}

  closeModal() {
    this.modalService.dismissAll();
  }
}
