export const NAVIGATION = {
  name: 'Workflow',
  icon: 'fa-tasks',
  path: '/module/workflow/',
  children: [{
    name: 'Vorlagen',
    path: '/module/workflow/grid',
    icon: 'fa-table'
  }, {
      name: 'Editor',
      path: '/module/workflow/crud/new',
      icon: 'fa-pencil'
  }, {
      name: 'Verlauf',
      path: '/module/workflow/instance/grid',
      icon: 'fa-history'
    }]
};
