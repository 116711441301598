import {RouterModule, Routes} from "@angular/router";
import {DummyComponent} from './component/dummy.component';

const APP_ROUTES: Routes = [{
  path: 'module/theme/dark',
  component: DummyComponent,
  canActivate: ['CoreGuard'],
  data: {
    needsLogin: false,
  }
}, {
  path: 'module/theme/light',
  component: DummyComponent,
  canActivate: ['CoreGuard'],
  data: {
    needsLogin: false,
  }
}];

export const routing = RouterModule.forRoot(APP_ROUTES);
