export const permission = {
  MYMEDAX_QUESTIONNAIRE_CREATE: 'MYMEDAX_QUESTIONNAIRE_CREATE',
  MYMEDAX_QUESTIONNAIRE_READ: 'MYMEDAX_QUESTIONNAIRE_READ',
  MYMEDAX_QUESTIONNAIRE_UPDATE: 'MYMEDAX_QUESTIONNAIRE_UPDATE',
  MYMEDAX_QUESTIONNAIRE_DELETE: 'MYMEDAX_QUESTIONNAIRE_DELETE',

  MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN: 'MYMEDAX_PATIENT_QUESTIONNAIRE_UNASSIGN',
  MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN: 'MYMEDAX_PATIENT_QUESTIONNAIRE_ASSIGN',
  MYMEDAX_PATIENT_UPDATE: 'MYMEDAX_PATIENT_UPDATE',
  MYMEDAX_PATIENT_QUESTIONNAIRE_READ: 'MYMEDAX_PATIENT_QUESTIONNAIRE_READ',
};
