<div class="modal-header">
  <h4 class="modal-title">{{_('Custom CSS')}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="alert alert-warning" role="alert">
    {{_(KEYS.THEMING.CSS_CUSTOMIZATION_WARNING)}}
  </div>
  <ngx-codemirror
    #codemirrorRef
    [(ngModel)]="customCss"
    [options]="codeMirrorOptions"
    (change)="onCssChange()"
    (blur)="onCssChange()"
    (keydown)="onCssChange()">
  </ngx-codemirror>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">
    {{_('Abbrechen')}}
  </button>

  <button type="button"
          class="btn btn-primary"
          (click)="onSave()">
    {{_('Speichern')}}

  </button>
</div>
