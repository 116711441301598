import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/de.js';

import {AppComponent} from './app.component';
import {ConfigModule} from '@ngmedax/config';
import {RegistryModule} from '@ngmedax/registry';
import {ValueModule} from '@ngmedax/value';
import {ErrorModule} from '@ngmedax/error';
import {TranslationModule} from '@ngmedax/translation';
import {LayoutModule} from '@ngmedax/layout';
import {ThemeModule} from '@ngmedax/theme';
import {TenantModule} from '@ngmedax/tenant';
import {LicenseGuardModule} from '@ngmedax/license-guard';
import {LoginModule} from '@ngmedax/login';
import {PermissionModule} from '@ngmedax/permission';
import {AssetModule} from '@ngmedax/asset';
import {ToastModule} from '@ngmedax/toast';
import {HomeModule} from "@ngmedax/home";
import {DeviceModule} from "@ngmedax/device";
import {ConfigGeneratorModule} from '@ngmedax/config-generator';
import {ImprintModule} from '@ngmedax/imprint';
import {SettingsModule} from '@ngmedax/settings';
import {SettingsThemingModule} from '@ngmedax/settings-theming';
import {SettingsLicenseModule} from '@ngmedax/settings-license';
import {SettingsVersionModule} from '@ngmedax/settings-version';
import {WebAllocatorModule} from '@ngmedax/web-allocator';
import {UmsModule} from '@ngmedax/ums';
import {PatientModule} from '@ngmedax/patient';
import {FiltergroupGridModule} from '@ngmedax/filtergroup-grid';
import {QuestionnaireGridModule} from '@ngmedax/questionnaire-grid';
import {QuestionnaireSubmissionModule} from '@ngmedax/questionnaire-submission';
import {CloudTranslatorModule} from '@ngmedax/cloud-translator';
import {SurveyGridModule} from '@ngmedax/survey-grid';
import {SurveyCodeModule} from '@ngmedax/survey-code';
import {WorkflowModule} from '@ngmedax/workflow';
import {PdfFormModule} from '@ngmedax/pdf-form';
import {QuestionnaireEditorModule} from '@ngmedax/questionnaire-editor';
import {QuestionnaireGroupModule} from '@ngmedax/questionnaire-group';
import {LicenseManagerModule} from '@ngmedax/license-manager';

import {config} from '../config';
import {modifier} from '../modifier';
import {routing} from './app.routing';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    /* mandatory core modules! */
    BrowserAnimationsModule,
    BrowserModule,
    RegistryModule,
    LayoutModule,
    ErrorModule,
    ValueModule,
    ToastModule.forRoot({alertHack: true, confirmHack: true}),
    ConfigModule.forConfig(config, modifier),

    /* optional feature modules */
    TranslationModule,
    ThemeModule,
    TenantModule,
    LicenseGuardModule,
    LoginModule,
    PermissionModule,
    AssetModule,

    LicenseManagerModule,
    QuestionnaireGridModule,
    QuestionnaireEditorModule,
    QuestionnaireGroupModule,
    QuestionnaireSubmissionModule,
    CloudTranslatorModule,
    PdfFormModule,
    SurveyGridModule,
    SurveyCodeModule,
    WorkflowModule,
    PatientModule,
    SettingsModule,
    SettingsThemingModule,
    SettingsLicenseModule,
    SettingsVersionModule,
    UmsModule,
    FiltergroupGridModule,
    DeviceModule,
    WebAllocatorModule,
    ImprintModule,
    ConfigGeneratorModule,
    HomeModule,
    routing
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
