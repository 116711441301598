import {Component, Input, Optional, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CodemirrorComponent} from '@ctrl/ngx-codemirror';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_THEMING_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';

declare const stylelint: any;
declare const CodeMirror: any;

export interface CustomCssDialogComponent extends Translatable {}

@Component({
  selector: 'app-custom-css-dialog',
  templateUrl: './custom-css-dialog.component.html',
  styleUrls: ['./custom-css-dialog.component.css']
})
@Translatable({scope: TRANSLATION_THEMING_SCOPE, keys: KEYS})
export class CustomCssDialogComponent  {
  @Input() customCss: string = '';
  @ViewChild('codemirrorRef') codemirrorRef!: CodemirrorComponent;
  public isCssSyntaxValid = true;
  public codeMirrorOptions = {
    mode: 'css',
    lineNumbers: true,
    gutters: ['CodeMirror-lint-markers'],
    lint: {
      async: true,
      getAnnotations: (text: string, callback: Function) => {
        stylelint.lint({
          code: text,
          config: {
            rules: {
              "property-no-unknown": [true, {
                "ignoreProperties": ["--*"]
              }],
              "selector-pseudo-class-no-unknown": [true, {
                "ignorePseudoClasses": ["host"]
              }]
            }
          }
        }).then((results: any) => {
          const annotations = results.results[0].warnings.map((warning: any) => ({
            message: warning.text,
            severity: warning.severity === 'error' ? 'error' : 'warning',
            from: CodeMirror.Pos(warning.line - 1, warning.column - 1),
            to: CodeMirror.Pos(warning.line - 1, warning.column)
          }));
          callback(annotations);
        }).catch((error: any) => {
          callback([]);
        });
      }
    }
  };

  public constructor(
    @Optional() private translationService: TranslationService,
    public activeModal: NgbActiveModal
  ) {

  }

  public onCssChange(): void {
    if (this.codemirrorRef?.codeMirror) {
      const lintState = (this.codemirrorRef.codeMirror as any).state?.lint;
      this.isCssSyntaxValid = !(lintState && lintState.marked && lintState.marked.length > 0);
    }
  }

  public onSave(): void {
    this.onCssChange();

    if (!this.isCssSyntaxValid) {
      return;
    }

    this.activeModal.close(this.customCss);
  }

  public onCancel(): void {
    this.activeModal.dismiss('cancel');
  }

  public _!: (text: string, variables?: any) => string;
  public getDateFormat!: (format: string, locale?: string) => string;
  public readonly KEYS = KEYS;
}
