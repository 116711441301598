import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ConfigService} from '@ngmedax/config';
import {LayoutService} from '@ngmedax/layout';
import {DummyComponent} from './component/dummy.component';

import {ThemeLoader} from './theme.loader';
import {ThemeService} from './theme.service';
import {ThemeGuard} from './theme.guard';
import {routing} from './theme.routing';


@NgModule({
  imports: [
    routing,
    CommonModule,
    HttpClientModule,
  ],
  declarations: [
    DummyComponent
  ],
  exports: [],
  providers: [
    ThemeGuard,
    ThemeService,
    {
      provide: APP_INITIALIZER,
      useFactory: ThemeLoader,
      deps: [ConfigService, ThemeService],
      multi: true
    }
  ]
})
export class ThemeModule {

  /**
   * Registers guard to load active theme into dom
   */
  public constructor(
    themeGuard: ThemeGuard,
    themeService: ThemeService,
    layoutService: LayoutService
  ) {
    layoutService.registerGuard(themeGuard, 10);

    const themeMode = themeService.getStoredTheme();

    // get user menu from layout service
    const userMenu: any[] = layoutService.getUserMenu();
    const reverseThemeMode = themeMode === 'dark' ? 'light' : 'dark';

    userMenu && userMenu.unshift({
      name: themeMode !== 'dark' ? 'Dark Theme' : 'Light Theme',
      path: `/module/theme/${reverseThemeMode}`,
      icon: themeMode !== 'dark' ? 'fa-moon-o' : 'fa-sun-o'
    });

    themeService.getThemeMode().subscribe((mode) => {
      const reverseThemeMode = mode === 'dark' ? 'light' : 'dark';
      const userMenu: any[] = layoutService.getUserMenu();
      const themeMenuItem = userMenu.find((item) => item.path === `/module/theme/${mode}`);

      if (themeMenuItem) {
        themeMenuItem.name = mode !== 'dark' ? 'Dark Theme' : 'Light Theme';
        themeMenuItem.icon = mode !== 'dark' ? 'fa-moon-o' : 'fa-sun-o';
        themeMenuItem.path = `/module/theme/${reverseThemeMode}`;
      }
    });
  }
}

