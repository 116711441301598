<div class="qa-grid">
  <h2 class="mb-4">{{_(KEYS.GRID.WORKFLOWS)}}</h2>
  <div class="card mb-5">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-search"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.SEARCH)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isSearchCollapsed = !isSearchCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isSearchCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isSearchCollapsed">
        <div class="qa-grid-search mt-2 mb-2">
          <div class="input-group qa-grid-search-workflow-name">
            <span class="input-group-addon" style="min-width: 116px">{{_(KEYS.GRID.NAME)}}:</span>
            <input class="form-control" [(ngModel)]="filter.name"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>
          <div class="input-group qa-grid-search-workflow-description">
            <span class="input-group-addon">{{_(KEYS.GRID.DESCRIPTION)}}:</span>
            <input class="form-control" [(ngModel)]="filter.description"
                   (paste)="onFilterChange()" (change)="onFilterChange()" (keydown)="onFilterChange()">
          </div>

          <div class="input-group qa-grid-search-workflow-questionnaires">
            <app-select2
              label="{{_(KEYS.GRID.QUESTIONNAIRES)}}:"
              [hideButton]="true"
              [multiple]="true"
              [options]="{width: '100%', placeholder: '...'}"
              [(selected)]="filter.questionnaires"
              (selectedChange)="onFilterChange()"
            >
              <option *ngFor="let q of questionnaires" [value]="q.id">
                {{q.meta.title[locale] || q.meta.title[defaultLocale] || '???'}}
              </option>
            </app-select2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title">
        <div class="btn-toolbar justify-content-between">
          <div class="justify-content-between qa-lh-2p5">
            <i class="fa fa-copy"></i>
            <h5 class="d-inline">{{_(KEYS.GRID.WORKFLOWS)}}</h5>
          </div>
          <button class="btn btn-primary d-inline-block" (click)="isGridCollapsed = !isGridCollapsed">
            <i class="fa" [ngClass]="{'fa-arrow-down': isGridCollapsed, 'fa-arrow-up': !isGridCollapsed}"></i>
          </button>
        </div>
      </div>
      <div class="card-text" [ngbCollapse]="isGridCollapsed">
        <table class="table">
          <thead>
          <tr>
            <th class="id-col qa-cursor-pointer" (click)="onSortChange('uid')">
              {{_(KEYS.GRID.ID)}}
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('name.de_DE')">
              {{_(KEYS.GRID.NAME)}}
              <i *ngIf="filter.sort === 'name.de_DE' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'name.de_DE' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('description.de_DE')" style="min-width:160px;">
              {{_(KEYS.GRID.DESCRIPTION)}}
              <i *ngIf="filter.sort === 'description.de_DE' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'description.de_DE' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('stages')">
              {{_(KEYS.GRID.STAGES)}}
              <i *ngIf="filter.sort === 'stages' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'stages' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('createdBy')">
              {{_(KEYS.GRID.USER)}}
              <i *ngIf="filter.sort === 'createdBy' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'createdBy' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('createdAt')">
              {{_(KEYS.GRID.CREATED_AT)}}
              <i *ngIf="filter.sort === 'createdAt' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'createdAt' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="qa-cursor-pointer" (click)="onSortChange('updatedAt')">
              {{_(KEYS.GRID.UPDATED_AT)}}
              <i *ngIf="filter.sort === 'updatedAt' && filter.order === 'asc'" class="fa fa-arrow-up"></i>
              <i *ngIf="filter.sort === 'updatedAt' && filter.order === 'desc'" class="fa fa-arrow-down"></i>
            </th>
            <th class="text-right">{{_(KEYS.GRID.OPTIONS)}}</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let workflow of workflows; let pos = index;">
            <tr [ngClass]="{'qa-tr-odd': pos % 2 === 0}">
              <td class="id-col">
                <span ngbTooltip="{{workflow.uid}}">{{workflow.uid | truncate: 5}}</span>
              </td>
              <td>
                {{workflow.name[locale] || workflow.name[defaultLocale] || '?'}}
              </td>
              <td>
                {{workflow.description[locale] || workflow.description[defaultLocale] || '?'}}
              </td>
              <td>
                <ng-container *ngFor="let stage of workflow.stages; let stagePos = index;">
                  <span class="badge badge-success">
                    - {{stage.name && stage.name[locale]
                    ? stage.name[locale] || stage.name[defaultLocale]
                    : _(KEYS.GRID.STAGE) + ' ' + (stagePos + 1)
                    }}
                  </span>
                  <span class="ml-1 badge badge-success"
                        [ngClass]="{'badge-success': stage.generateExports, 'badge-secondary': !stage.generateExports}"
                        [ngbTooltip]="_(KEYS.GRID.GENERATE_EXPORTS)">
                    <i class="fa fa-download"></i>
                  </span>
                  <span class="ml-1 badge"
                        [ngClass]="{'badge-success': stage.generateFormPDFs, 'badge-secondary': !stage.generateFormPDFs}"
                        [ngbTooltip]="_(KEYS.GRID.GENERATE_FORM_PDFS)">
                    <i class="fa fa-file-pdf-o"></i>
                  </span>
                  <span class="ml-1 badge"
                        [ngClass]="{'badge-success': stage.prefill, 'badge-secondary': !stage.prefill}"
                        [ngbTooltip]="_(KEYS.GRID.PREFILL)">
                    <i class="fa fa-pencil-square-o"></i>
                  </span>
                  <br>
                  <ng-container *ngIf="stage.questionnaires && stage.questionnaires.length > 0">
                    <ng-container *ngFor="let questionnaireId of stage.questionnaires">
                      <div class="badge">
                        • {{questionnaireTitles && questionnaireTitles[questionnaireId]
                        ? questionnaireTitles[questionnaireId][locale]
                        || questionnaireTitles[questionnaireId][defaultLocale]
                        : '...'}}
                      </div><br>
                    </ng-container>
                    <br>
                  </ng-container>
                </ng-container>
              </td>
              <td>
                {{workflow.createdBy || ''}}
              </td>
              <td style="min-width:135px;">
                {{workflow.createdAt | datex:dateFormat}}
              </td>
              <td style="min-width:135px;">
                {{workflow.updatedAt | datex:dateFormat}}
              </td>
              <td class="text-right" style="width:90px;">
                <a class="btn btn-sm btn-primary"
                   [routerLink]="['/module/workflow/crud', workflow.id]"
                   ngbTooltip="{{_(KEYS.GRID.EDIT)}}"
                   placement="top">
                  <i class="fa fa-edit"></i>
                </a>
                <button class="btn btn-sm btn-danger ml-1"
                        (click)="onDeleteWorkflow(workflow)"
                        ngbTooltip="{{_(KEYS.GRID.DELETE)}}"
                        placement="top">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="btn-toolbar justify-content-between mt-2 mb-2">
          <div class="justify-content-between">
            <div class="input-group">
              <div class="input-group qa-num-per-page">
                <select class="form-control custom-select" [(ngModel)]="displayPerPage" (change)="onPagingChange()">
                  <option value="2">2</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
                <span class="input-group-addon">{{_(KEYS.GRID.ENTRIES)}}</span>
              </div>
            </div>
          </div>
          <ngb-pagination
            [pageSize]="displayPerPage"
            [maxSize]="9"
            [rotate]="true"
            [(page)]="gridPageNumber"
            [collectionSize]="total"
            (pageChange)="onPagingChange()">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
