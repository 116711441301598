import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

export type ThemeMode = 'light' | 'dark';

@Injectable()
export class ThemeService {
  private readonly THEME_KEY = 'selected-theme';
  private themeMode = new BehaviorSubject<ThemeMode>(this.getStoredTheme());
  private css: string;
  private cssLoadingFailed = false;
  private cssFilePath: string;
  private logo: string;
  private title: string;
  private favicon: string;

  public constructor(private http: HttpClient) {
    this.applyTheme(this.themeMode.value);
  }

  public load(cssFilePath: string) {
    this.cssFilePath = cssFilePath;

    return new Promise<void>((resolve) => {
      this.http.get(cssFilePath, {responseType: 'text' })
        .subscribe(
          response => {
            this.css = <string>response;
            resolve();
          },
          error => {
            this.cssLoadingFailed = true;
            resolve();
          });
    });
  }

  public getCss() {
    return this.css;
  }

  public getCssFilePath() {
    return this.cssFilePath;
  }

  public setLogo(logo: string) {
    this.logo = logo;
  }

  public getLogo() {
    return this.logo;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public getTitle() {
    return this.title;
  }

  public setFavicon(favicon: string) {
    this.favicon = favicon;
  }

  public getFavicon() {
    return this.favicon;
  }

  public hasFailedToLoadCss() {
    return this.cssLoadingFailed;
  }

  public getThemeMode(): Observable<ThemeMode> {
    return this.themeMode.asObservable();
  }

  public toggleTheme(): void {
    const newTheme = this.themeMode.value === 'light' ? 'dark' : 'light';
    this.setThemeMode(newTheme);
  }

  public setThemeMode(theme: ThemeMode): void {
    localStorage.setItem(this.THEME_KEY, theme);
    this.themeMode.next(theme);
    this.applyTheme(theme);
  }

  public getStoredTheme(): ThemeMode {
    return (localStorage.getItem(this.THEME_KEY) as ThemeMode) || 'light';
  }

  private applyTheme(theme: ThemeMode): void {
    document.body.classList.remove('light-theme', 'dark-theme');
    document.body.classList.add(`${theme}-theme`);
  }
}
