<div class="{{cmpCssClasses}}" [ngClass]="{withLabel: !hideLabel, withButton: !hideButton}" #cmpEl>
  <div class="input-group">
    <div class="select">
      <div class="input-group">
        <span class="input-group-addon" *ngIf="!hideLabel">{{label}}</span>
        <select class="form-control qa-custom-select {{selectCssClasses}}" #select2 [attr.multiple]="multiple ? true: null">
          <ng-content></ng-content>
        </select>
        <span *ngIf="!hideButton" class="input-group-addon add-btn {{btnCssClasses}}" (click)="onSubmit()">
          <i class="fa {{buttonFaIcon}}"></i>
        </span>
        <span *ngIf="showDeleteButton" class="input-group-addon delete-btn btn-danger" (click)="onDelete()">
          <i class="fa fa-trash"></i>
        </span>
      </div>
    </div>
  </div>
</div>
