import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {Ast, ExpressionCompilerService} from '@ngmedax/expression-compiler';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {TRANSLATION_EDITOR_SCOPE} from '../../../../../../constants';
import {KEYS} from '../../../../../../translation-keys';
import {QuestionnaireConditionService} from '../../../../../services/questionnaire-condition.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface ConditionsEditorGroupComponent extends Translatable {}

@Component({
  selector: 'app-qa-conditions-editor-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './conditions-editor-group.component.html',
  styleUrls: ['./conditions-editor-group.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class ConditionsEditorGroupComponent implements OnInit {
  @Input() group: Ast.Group = {type: 'group', operator: '&&', elements: []};
  @Input() question: Questionnaire.Container;
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();
  public Ast = Ast;

  public constructor(
    @Optional() private translationService: TranslationService,
    private compiler: ExpressionCompilerService,
    private conditions: QuestionnaireConditionService,
    private ref: ChangeDetectorRef
  ) {
  }

  public ngOnInit() {
    if (this.question) {
      !this.question.conditions && (this.question.conditions = {show: []});
      const expression = this.question.conditions.expression;
      typeof expression === 'string' && expression && (this.group = this.compiler.parse(expression));

      // compiles all conditions to expression and adds it to current question when change was detected
      this.conditions.onChange.subscribe(() => {
        this.canSave() && this.onSave();
        this.triggerChangeDetection();
      });
    }

    !this.group.operator && (this.group.operator = '&&');
  }

  public onSave() {
    console.log(this.group);
    this.question.conditions.expression = this.compiler.compile(this.group);
    this.question.conditions.expression === '()' && delete(this.question.conditions.expression);
  }

  public onAddGroup() {
    const group = <Ast.Group>{type: Ast.TYPE.GROUP, operator: '&&', elements: []};
    this.group.elements.push(group);
    this.triggerChangeDetection();
  }

  public onAddExpression() {
    const expression = <Ast.Expression>{type: Ast.TYPE.EXPRESSION, variable: null, operator: '>', value: ''};
    this.group.elements.push(expression);
    this.triggerChangeDetection();
  }

  public onClickDelete() {
    this.onDelete.emit();
  }

  public onElementDelete(position: number) {
    this.group.elements.splice(position, 1);

    // empty group is useless and can't be auto saved. therefor we delete it
    !this.group.elements.length && this.onClickDelete();

    setTimeout(() => {this.triggerChangeDetection(); this.conditions.onChange.emit()}, 20);
  }

  public hasGroup(): boolean {
    return typeof this.group === 'object';
  }

  public canSave(): boolean {
    if (!this.question) {
      return false;
    }

    const currentStmt = this.compiler.compile(this.group);
    const isValid = !currentStmt.match(/(\(\))|(null)|("""+)/);
    const isDeleted = currentStmt === '()' && !!this.question.conditions.expression;
    return isValid || isDeleted;
  }

  public triggerChangeDetection() {
    try {
      this.ref.detectChanges();
      this.ref.markForCheck();
    } catch (error) {
      // we can ignore this
    }
  }
}
