import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {LayoutService} from '@ngmedax/layout';
import {ValueService} from '@ngmedax/value';
import {Select2Module} from '@ngmedax/select2';
import {ClickOutsideModule} from 'ng-click-outside';

import {QuestionnaireSubmissionGridComponent} from './components/grid/questionnaire-submission-grid.component';
import {QuestionnaireSubmissionTableExportComponent} from './components/table-export/questionnaire-submission-table-export.component';
import {NgbDateDeParserFormatter} from './date-parser/ngb-date-de-parser-formatter';
import {NAVIGATION} from './questionnaire-submission.navigation';
import {QuestionnairePathHashService} from './services/questionnaire/path-hash.service';
import {TableExportService} from './services/table-export.service';
import {SubmissionService} from './services/submission.service';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {routing} from './questionnaire-submission.routing';

import {QuestionnaireAttributeService} from './services/questionnaire/attributes.service';
import {QuestionnaireMatrixPackerService} from './services/questionnaire/matrix-packer.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    Select2Module,
    ClickOutsideModule,
    routing
  ],
  declarations: [
    QuestionnaireSubmissionGridComponent,
    QuestionnaireSubmissionTableExportComponent,
    TruncatePipe,
    DatexPipe

  ],
  exports: [],
  providers: [
    QuestionnairePathHashService,
    QuestionnaireAttributeService,
    QuestionnaireMatrixPackerService,
    ValueService,
    SubmissionService,
    TableExportService,
    {provide: NgbDateParserFormatter, useClass: NgbDateDeParserFormatter}
  ]
})
export class QuestionnaireSubmissionModule {
  constructor(private layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);
  }
}
